// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.


.svg-logo {
    height: 90px;
}


.navbar-dark {
    .navbar-brand-dynamic-color,
    .navbar-toggler {
        svg {

            path {
                fill: white !important;
            }

            [stroke]:not([stroke="none"]) {
                stroke: $white;
            }

            [fill]:not([fill="none"]) {
                fill: $white;
            }
        }
    }
}



.navbar{
    &.scrolled, &.navbar-toggled-show{
        background-color: $white;
        &.navbar-dark{
            .svg-logo {
                    
                    height: 60px;
                    margin-top: -10px;
                    margin-bottom:-10px;
            }
            

            .navbar-brand-dynamic-color, .navbar-toggler{
                svg{


                    path#_1-1,
                    path#_2-2,
                    path#_3-3,
                    path#_4-4,
                    path#_5-5,
                    path#_6-6,
                    path#_7-7
                    {
                        fill:#3b3735 !important;
                        fill:white !important;
                        
                    }
              
                


                    // path#_1-1,
                    // path#_2-2,
                    // path#_3-3 {
                    //     fill:#3b3735 !important;

                    // }

                    // path#_4-4,
                    // path#_5-5 {
                    //     fill:#3b3735 !important;
                    // }

                    // path#_6-6,
                    // path#_7-7
                    // {
                    //     fill:#3b3735 !important;
                     
                    // }



                    path#violet-8 {
                          
                            fill:#3b3735 !important;
                            fill:#79287d !important;
                            fill:white !important;
                        }
    
                        path#jaune-9 {
                            //display:none;
                            fill:#3b3735 !important;
                            fill:#f8ad2f !important;
                            fill:white !important;
                        }
                        path#vert-10 {
                            //display:none;
                            fill:#3b3735 !important;
                            fill:#028e89 !important;
                            fill:white !important;
                        }

                    
                    [stroke]:not([stroke="none"]) {
                        stroke: $gray-900;
                        fill:white !important;
                    }
        
                    [fill]:not([fill="none"]) {
                        fill: $gray-900;
                        fill:white !important;
                    }
                }
            }
        }
    }
}

.st0 {
    fill: white !important;
}
.st3 {
    fill: white !important;
}
.st2 {
    fill: white !important;
}
.st1 {
    fill: white !important;
}



.bg-gradient {
    //background: linear-gradient(to right, #79287d, #f8ad2f, #028e89);
    // /* background-image: url(/assets/img/blog/thumb-3.jpg); */
    // background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12) !important;

    background: linear-gradient(8deg,#00b4ae, #008793, #a732ad, #79287d,#af7c24, #f8ad2f) !important;
	
	background-size: 1000% 1000%  !important;
	-webkit-animation: AnimationName 60s ease infinite;
	        animation: AnimationName 60s ease infinite;
}




@-webkit-keyframes AnimationName {
    0%{background-position:0% 86%}
    50%{background-position:100% 15%}
    100%{background-position:0% 86%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 86%}
    50%{background-position:100% 15%}
    100%{background-position:0% 86%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 86%}
    50%{background-position:100% 15%}
    100%{background-position:0% 86%}
}
@keyframes AnimationName {
    0%{background-position:0% 86%}
    50%{background-position:100% 15%}
    100%{background-position:0% 86%}
}